import { ArrowRightIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'
import React from 'react'

export const CallToAction = () => (
  <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-6 mt-8 print:hidden">
    <a href="https://calendly.com/trybe-ricky/15min" target="_blank" title="Schedule a demo" className="border border-gray-300 shadow-sm bg-white text-grape rounded-lg font-medium text-lg p-4 px-6 hover:gray-50">
      Schedule a demo
    </a>
    <Link
      to="/contact-us"
      title="Get in touch"
      className="text-white font-medium"
    >
      or{' '}<span className="text-violet">get in touch{' '}<ArrowRightIcon className="w-6 h-6 inline"/></span>
    </Link>
  </div>
)
